<template>
  <div v-bind:id="`realEstate-picker-${uniqueId}`" class="d-inline-block w-100 realEstate-picker">
    <!--unique input-->
    <div dir="ltr" class="v-select w-100 vs--single vs--searchable" v-if="multiple !== true">
      <div class="vs__dropdown-toggle">
        <!--selected-->
        <div class="vs__selected-options">
          <span class="vs__selected invisible" v-if="uniqueRealEstateName == null">none</span>
          <span class="vs__selected" v-bind:class="{'text-warning': uniqueRealEstateName.valid !== true}"
                v-else>{{ uniqueRealEstateName.label }}</span>
        </div>
        <!--actions-->
        <div class="vs__actions cursor-pointer">
          <button type="button" class="vs__clear" v-if="clearable === true && uniqueRealEstateName != null" @click="clear">
            <feather-icon icon="XIcon"/>
          </button>
          <feather-icon icon="HomeIcon" @click="()=>toggleModal(true)"/>
        </div>
      </div>
    </div>
    <!--Multiple input-->
    <!--Modal table-->
    <b-modal header-bg-variant="primary" no-fade v-model="modal.active" size="xl" :ref="modalId" :id="modalId" :hide-footer="true" centered
             @show="refresh">
      <div>
        <b-table hover v-bind:items="realEstateTableData" v-bind:fields="fields" @row-clicked="onRowClicked">
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mx-1"></b-spinner>
              <strong>{{ $t('Loading') }}...</strong>
            </div>
          </template>
          <template #cell(address)="data">
            <div>
              <div style="white-space: nowrap">
                <span v-if="data.item.address" class="d-inline-block"
                      style="margin-left: 5px">{{ data.item.address }},</span>
                <span v-if="data.item.house" class="d-inline-block"
                      style="margin-left: 5px">{{ data.item.house }}/{{ data.item.apt }},</span>
                <span v-if="data.item.city" class="d-inline-block" style="margin-left: 5px">{{ data.item.city }}</span>
              </div>
              <div style="margin-left: 5px">
                <strong>{{ $t('Zip') }}: </strong>{{ data.item.zip }}
              </div>
            </div>
          </template>
          <template #cell(attachments)="data">
            <b-img
                v-bind=" { blank: (data.value == null || data.value[0] == null), blankColor: '#777', width: 50, height: 50,
                                src:(data.value != null && data.value[0] != null ? mergeSrcUrl('https://treasurehome.at',data.value[0]) : null)  }"
                blank-color="#475F7B"
                rounded
                alt="Rounded image"
                class="d-inline-block m-1"
            />
          </template>
          <template #cell(realEstatePrices)="data">
            <div>
              {{ getPrices(data) }}
            </div>
          </template>


        </b-table>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "realEstatePicker",
  data: () => ({
    uniqueId: 0,
    modal: {
      active: false,
      realEstates: [],

    },

    sort: {
      col: "Creationtime",
      order: "desc"
    },
    selected: {
      unique: null,
      multiple: null,
      data: [],
      loading: false,
      error: false,
    }
  }),
  props: {
    value: {
      required: true
    },
    multiple: {
      type: Boolean,
      default: () => false
    },
    clearable: {
      type: Boolean,
      default: () => false
    }
  },
  watch: {
    value: {
      handler() {
        this.selected.multiple = null;
        this.selected.unique = null;
        if (this.multiple !== true) {
          this.selected.unique = this.value;
        } else {
          if (this.value != null && !Array.isArray(this.value)) {
            this.$emit('input', (!isNaN(this.value) ? [this.value] : []))
            return;
          }
          this.selected.multiple = this.value;
        }
        this.refreshSelected();
      },
      immediate: true
    },
    "modal.filter": {
      handler() {
        this.refresh();
      },
      deep: true
    }
  },
  created() {
    this.uniqueId = this._uid
  },
  computed: {
    ...mapGetters('realEstates', ['allStatus','statusVariants','getPrices','getFeaturedStatus','getFullAddress']),
    fields() {
      let $this = this;
      return  [

        {
          key: 'attachments',
          label: $this.$t('Main image'),
          sortable: true,
          active: true,
        },
        {
          key: 'address',
          label: $this.$t('Address'),
          sortable: true,
          active: true,
        },

        {
          key: 'realEstatePrices',
          label: $this.$t('Price'),
          sortable: false,
          active: true,
        },
        {
          key: 'realEstateArea.totalSpace',
          label: $this.$t('Total space'),
          sortable: false,
          active: true,
        },
        {
          key: 'realEstateArea.rooms',
          label: $this.$t('Rooms'),
          sortable: true,
          active: true,
        }
      ]
    },
    realEstateTableData() {
      let realEstates = this.modal.realEstates || [];
      let currentRealEstateIds = (this.selected.data || []).map(c => c.id);
      realEstates.forEach(realEstate => realEstate["_rowVariant"] = (currentRealEstateIds.includes(realEstate.id)) ? 'success' : null)
      return realEstates;
    },
    modalId() {
      return `realEstate-modal-picker-${this.uniqueId}`
    },
    uniqueRealEstateName() {
      if (this.value == null || Array.isArray(this.value) || this.value === "")
        return null;
      if (!Array.isArray(this.selected.data) || this.selected.data.length === 0)
        return {label: `${this.selected.unique} - ${this.$t('Unknown')}`, valid: false};
      if (this.selected.data[0].id !== this.selected.unique) {
        return {label: `${this.selected.unique} - ${this.$t('Unknown')}`, valid: false};
      }
      return {label: `${this.value.substr(this.value.length-6)} - ${this.getFullAddress(this.selected.data[0])}`, valid: true};
    },
  },
  methods: {
    ...mapActions('realEstates', ['getAllProperties']),
    refreshSelected() {
      let $this = this;
      this.selected.loading = true;
      if (this.value == null || (Array.isArray(this.value) && this.value.length === 0)) {
        this.clear(true);
        this.selected.loading = false;
        return;
      }
      this.getAllProperties()
          .then(data => {
            $this.selected.data = data['RealEstates'];
          }, () => $this.selected.error = true).finally(() => $this.selected.loading = false);
    },
    clear(force) {
      if (this.clearable !== true && force !== true)
        return;
      this.selected.multiple = null;
      this.selected.unique = null;
      this.selected.data = [];
      this.$emit('input', null)
    },
    removeRealEstate(realEstateId) {
      let currentValue = this.value != null ? [...this.value] : [];
      const index = currentValue.findIndex(rId=>rId===realEstateId);
      if (index < 0) {
        return false
      }
      currentValue.splice(index, 1);
      this.$emit('input', currentValue);
      return true;
    },

    refresh() {
      this.getAllProperties().then(this.onRealEstatesReceived);
    },
    onRealEstatesReceived(data) {
      this.modal.realEstates = data['RealEstates'];

      this.$root.$emit('bv::refresh::table')
    },
    toggleModal(status) {
      this.modal.active = status != null ? status : !this.modal.active;
    },
    onRowClicked(realEstate) {
      if (this.multiple !== true) {
        this.$emit('input', realEstate.id);
        this.modal.active = false;
        return
      }
      if(this.value == null){
        this.$emit('input', [realEstate.id]);
        return;
      }
      if (this.removeRealEstate(realEstate.id) === true) {
        return;
      }
      this.$emit('input', [...this.value, realEstate.id]);
    }
  }
}
</script>

<style lang="scss">
.realEstate-picker {
  .input-group > .realEstate-picker-unique.input-group-append {
    > .btn.realEstate-picker-clear {
      padding-right: 4px;
      padding-left: 4px;
      border: none !important;
    }

    > .btn.realEstate-picker-add {
      border: none !important;
    }

  }
}
</style>
