<template>
  <div>
    <v-date-picker  class="inline-block h-full" :masks="masks" v-bind="$props" v-model="valueDate"
                   is-range ref="picker" is24hr>
      <template v-slot="{ inputValue, inputEvents }">
        <div class="date-picker-range-container d-flex align-items-center" :class="{'clearable':clearable}">
          <div class="d-inline-block">
            <div class="flex items-center">
              <b-input-group :size="size" v-on="inputEvents.start" >
                <template #prepend>
                  <b-input-group-text><i class="far fa-calendar-alt"></i></b-input-group-text>
                </template>
                <b-form-input class="text-center" type="text" v-bind:value="formattedTime.start" :placeholder="$t('start')" readonly />
                <template #append v-if="clearable">
                  <b-input-group-text @click="clearInput"><i class="fa-solid fa-xmark"></i></b-input-group-text>
                </template>
              </b-input-group>
            </div>
          </div>
          <div class="date-picker-arrow"><i class="fas fa-arrow-right"></i></div>
          <div class="d-inline-block">
            <div class="flex items-center">
              <b-input-group :size="size" v-on="inputEvents.end">
                <template #prepend>
                  <b-input-group-text><i class="far fa-calendar-alt"></i></b-input-group-text>
                </template>
                <b-form-input class="text-center" type="text" v-bind:value="formattedTime.end" :placeholder="$t('end')" readonly/>
                <template #append v-if="clearable">
                  <b-input-group-text @click="clearInput"><i class="fa-solid fa-xmark"></i></b-input-group-text>
                </template>
              </b-input-group>
            </div>
          </div>
        </div>
      </template>
<!--      <template v-slot:footer>
        <div class="bg-gray-100 text-center p-1 border-t rounded-b-lg width-50">
&lt;!&ndash;          <b-button variant="outline-primary" size="sm" @click="setThisMonth">{{$t('thisMonth')}}</b-button>
          <b-button variant="outline-primary" size="sm" @click="setLastMonth">{{$t('lastMonth')}}</b-button>
          <b-button variant="outline-primary" size="sm" @click="setDays(30)">{{$t('last30Days')}}</b-button>
          <b-button variant="outline-primary" size="sm" @click="setThisYear">{{$t('thisYear')}}</b-button>
          <b-button block variant="outline-primary" size="sm" @click="setToToday">{{$t('today')}}</b-button>&ndash;&gt;
        </div>
      </template>-->
    </v-date-picker>
  </div>
</template>

<script>

export default {
  name: 'calendarPickerRange',
  data: () => ({
    masks: {
      L: 'yyyy-dd-MM',
      input: ['yyyy-dd-MM'],
      inputDateTime: ['yyyy-dd-MM HH:mm'],

      // ...optional `title`, `weekdays`, `navMonths`, etc
    }
  }),
  props: {
    value: {
      required: true,
      default: () => null
    },
    mode: {
      required: false,
      default: () => 'date'
    },
    size: {
      required: false,
      default: () => 'md'
    },
    minDate: {},
    maxDate: {},
    disabledDates: {},
    availableDates: {},
    clearable:{
      required: false,
      default: () => true
    }
  },
  computed: {
    valueDate: {
      get() {
        if (typeof this.value === 'object' && this.value !== null) {
          return this.value
        } else {
          return {
            start: null,
            end: null
          }
        }
      },
      set(val) {
        if (val.start != null) {
          val.start = this.$moment(val.start)
              .format(this.i18nInputKey)
        }
        if (val.end != null) {
          val.end = this.$moment(val.end)
              .format(this.i18nInputKey)
        }
        this.$emit('input', val)
      }
    },
    formattedTime() {
      let result = {
        start: '',
        end: ''
      }
      let startTime = this.$moment(this.valueDate.start, this.i18nInputKey)
      let endTime = this.$moment(this.valueDate.end, this.i18nInputKey)
      if (startTime.isValid()) {
        result.start = startTime.format('DD.MM.YYYY'); // Format to yyyy-MM-dd
      }
      if (endTime.isValid()) {
        result.end = endTime.format('DD.MM.YYYY'); // Format to yyyy-MM-dd
      }
      return result
    },
    i18nMaskKey() {
      return this.$t('timeFormat.' + this.mode + '.mask')
    },
    i18nFormatKey() {
      return this.$t('timeFormat.' + this.mode + '.format')
    },
    i18nInputKey() {
      return this.mode === 'date' ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm'
    },
  },
  methods: {
    setInput(val) {
      this.$emit('input', val)
    },
    clearInput() {
      this.$emit('input', null);
      this.forceUpdate();
    },
    setToToday() {
      let valueDate = {
        start: this.$moment().format(this.i18nInputKey),
        end: this.$moment().format(this.i18nInputKey)
      }
      this.valueDate = valueDate;
      this.forceUpdateValue(valueDate);
      this.moveCalendar(valueDate);
    },
    setThisMonth(){
      let valueDate = {
        start: this.$moment().startOf('month').format(this.i18nInputKey),
        end: this.$moment().format(this.i18nInputKey)
      }
      this.valueDate = valueDate;
      this.forceUpdateValue(valueDate);
      this.moveCalendar(valueDate);
    },
    setLastMonth(){
      let valueDate = {
        start: this.$moment().subtract(1, 'month').startOf('month').format(this.i18nInputKey),
        end: this.$moment().subtract(1, 'month').endOf('month').format(this.i18nInputKey)
      }

      this.valueDate = valueDate;
      this.moveCalendar(valueDate);
      this.forceUpdateValue(valueDate);
    },
    setDays(days){
      let valueDate = {
        start: this.$moment().subtract(days, 'days').format(this.i18nInputKey),
        end: this.$moment().format(this.i18nInputKey)
      }
      this.valueDate = valueDate;
      this.moveCalendar(valueDate);
      this.forceUpdateValue(valueDate);
    },
    setThisYear(){
      let valueDate = {
        start: this.$moment().startOf('year').format(this.i18nInputKey),
        end: this.$moment().format(this.i18nInputKey)
      }
      this.valueDate = valueDate;
      this.moveCalendar(valueDate);
      this.forceUpdateValue(valueDate);
    },
    forceUpdateValue(valueDate){
      this.$refs.picker.forceUpdateValue(valueDate);
    },
    moveCalendar(valueDate){
      let moment = this.$moment(valueDate.start,this.i18nInputKey);
      let month = parseInt(moment.format("M"));
      let year = parseInt(moment.format("YYYY"));
      this.$refs.picker.move({ month, year });
    }
  }
}
</script>

<style lang="scss">
.date-picker-range-container {
  .date-picker-arrow {
    margin-right: 5px;
    margin-left: 5px;
  }
  .input-group {
    .input-group-prepend {
      .input-group-text {
        border-right: none !important;
        padding-right: 1px;
      }
    }
    input.form-control {
      border-left: none !important;
    }
  }
  &.clearable {
    .input-group-append{
      .input-group-text {
        padding-left: 1px;
        padding-right: 6px;
        cursor: pointer;
      }
    }
    input.form-control {
      border-left: none !important;
      border-right: none !important;
      padding: 0;
    }
  }
}
</style>
