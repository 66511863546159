<template>
  <div class="contract-component">

    <b-card no-body class="m-2">
      <b-card-header style="background-color: #c2aa8e" class="pb-50">
        <b-row no-gutters class="w-100">
          <b-col>
            <h5 style="color: white">{{ $t("Filters") }}</h5>
          </b-col>
          <b-col>
            <div class="d-flex justify-content-end">
              <b-link style="color: white" @click="refresh()">
                <feather-icon icon="RefreshCcwIcon" size="16"/>
              </b-link>
            </div>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body class="border">
        <b-row class="mt-2">
          <b-col cols="12" md="3" lg="3" class="mb-md-0 mb-2">

            <b-form-group :label="$t('Status')" label-for="realEstate-branch">
              <v-select
                  multiple

                  :clearable="true"
                  v-model="filter.in.status"
                  :options="allContractStatus"
                  class="w-100"
                  label="text"
                  :reduce="(branch) => branch.value"

              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>{{ $t("firstname") }}</label>
            <b-form-input
                v-model="filter.eq.client.firstname"
                class="d-inline-block mr-1"
            />
          </b-col>

          <!-- Id -->
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>{{ $t("lastname") }}</label>
            <b-form-input
                v-model="filter.eq.client.lastname"
                class="d-inline-block mr-1"
            />
          </b-col>

          <!-- Id -->
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>{{ $t("Email") }}</label>
            <b-form-input
                v-model="filter.eq.client.email"
                class="d-inline-block mr-1"
            />
          </b-col>
          <!-- Id -->
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>{{ $t("Real estate") }}</label>
            <real-estate-picker v-model="filter.eq.propertyId"/>

          </b-col>
          <b-col cols="12" md="3" lg="3">
            <b-form-group :label="$t('Plattform')" label-for="realEstate-type">
              <v-select
                  v-model="filter.eq.bnbStyleRent.platform"
                  :options="[
                      {value:'Booking',label:('Booking')},
                      {value:'Airbnb',label:('Airbnb')},
                      {value:'Treasure Home',label:('Treasure Home')},
                      {value:'Expedia',label:('Expedia')},
                      {value:'Vrbo',label:('Vrbo')},
                      {value:'Agoda',label:('Agoda')},
                      {value:null,label:('Alle Plattformen')}
                      ]"
                  label="label"
                  :reduce="(status) => status.value"
                  class="w-100"
                  :clearable="false"


              />
            </b-form-group>
          </b-col>

          <b-col  md="4" >
            <b-form-group :label="$t('checkinrange')" label-for="realEstate-branch">

              <calendar-picker-range v-model="datesRange"/>
            </b-form-group>

          </b-col>
          <b-col  md="2" >
            <b-form-group label="Reset check-in filter" label-for="realEstate-branch">

              <b-button variant="danger" @click="datesRange=null">
                <i class="fas fa-arrow-circle-left"></i> Reset
              </b-button>
            </b-form-group>

          </b-col>
        </b-row>

      </b-card-body>
    </b-card>


    <b-card no-body class="m-2">
      <div class="card-header" style="overflow: hidden">
        <b-badge variant="primary" style="font-weight: bold" class="card-title  m-0">{{ $t('Contracts') }}</b-badge>
        <b-col>
          <div class="d-flex justify-content-end">
            <b-link style="color: inherit" @click="refresh()">
              <feather-icon icon="RefreshCcwIcon" size="16"/>
            </b-link>
          </div>
        </b-col>
      </div>
      <!--Card Content-->
      <b-card-body>
        <div class="">
          <!-- Table Top -->
          <b-row no-gutters class="mb-1">
            <!-- Per Page -->
            <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <label>{{ $t('Show') }}</label>
              <v-select
                  v-model="page.amount"
                  @option:selected="refresh"
                  :options="[10, 25, 50, 100,500]"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
              />
              <label>{{ $t('entries') }}</label>
            </b-col>
          </b-row>
        </div>
        <!--Table-->
        <b-table
            thead-class="customHeader"
            ref="contracts-table"
            striped
            hover
            responsive
            v-bind:items="contracts"
            v-bind:fields="fields"
            :busy="loading"
            small
            @sort-changed="onSort"
            @row-clicked="onRowClick"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mx-1"></b-spinner>
              <strong>{{ $t('Loading') }}...</strong>
            </div>
          </template>

          <template #cell(client)="data">
            <div class="d-flex align-items-center">
              <b-avatar variant="primary" size="sm">
                <feather-icon icon="UserIcon" size="18"/>
              </b-avatar>
              <div class="ml-2">
                <b>{{ data.value.firstname }} {{ data.value.lastname }}</b>
                <div>
                  <b>{{ data.value.email }}</b>
                </div>
                <div>
                  <b>{{ data.value.phone }}</b>
                </div>
              </div>
            </div>
          </template>

          <template class="text-center" #cell(bnbStyleRent)="data">
            <b-img class="mb-1" width="80px" :src="getSource(data.value.platform)"></b-img>

          </template>
          <template class="text-center" #cell(moveDate)="data">
            <b-badge variant="primary"
                     style="font-size: 0.875rem; max-width: 100px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">

              {{ convertDateFormat(data.value) }}
            </b-badge>

          </template>
          <template #cell(creationTime)="data">
            <span style="font-weight: bold"> {{ convertDateFormatTime(data.value) }}</span>

          </template>

          <template class="text-center" #cell(status)="data">
            <b-badge v-bind:variant="statusData(data.value).variant"
                     style="font-size: 0.875rem; max-width: 100px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">

              {{ $t(statusData(data.value).value) }}
            </b-badge>
          </template>


          <template #cell(propertyId)="data" class="p-0">
            <div style="background-color: rgba(178,159,136,0.71)"
                 class="rounded-custom feat_property list favorite_page style2">
              <div class="thumb">
                <VueSlickCarousel class="feature-flat-carousel" v-bind="carouselSettings">
                  <div>
                    <img class="img-whp img-slick" :src="getPropertiesPicture(properties[data.value])"
                         alt="">
                  </div>
                </VueSlickCarousel>
                <div class="thmb_cntnt">
                  <ul class="tag mb0"
                      v-if="properties[data.value].marketingType && properties[data.value].marketingType.rent">
                    <li class="list-inline-item dn"></li>
                    <li class="list-inline-item"><a href="JavaScript:void(0)">{{ $t("For Rent") }}</a></li>
                  </ul>
                </div>
              </div>
              <div class="details">
                <div class="tc_content pt-2">
                  <h4>{{ properties[data.value].propertyType }}</h4>
                  <p><span class="flaticon-placeholder"></span>
                    {{ properties[data.value].address }} {{
                      properties[data.value].house
                    }}/{{ properties[data.value].apt }}
                    <span v-if="properties[data.value].address || properties[data.value].house"></span>
                    {{ properties[data.value].city }}
                  </p>

                  <span v-if="data.item.bnbStyleRent!=null">
                            {{
                      convertToEURO(data.item.bnbStyleRent.totalPayment)
                    }}
                          </span>
                  <span v-else>
                            {{
                      convertToEURO(properties[data.value].realEstatePrices.rentTotal)
                    }}
                          </span>

                </div>
              </div>
            </div>
          </template>

          <template #cell(actions)="data">
            <b-button v-if="canDownload(data.item.bnbStyleRent)"
                      style="background-color: transparent!important;border-color: transparent!important;" size="sm"
                      @click="downloadInvoice(data.item)" v-b-tooltip.hover
                      title="Download Invoice"
            >
              <i style="color: red;font-size: 28px" class="fa fa-file-pdf"></i>
            </b-button>
            <b-badge class="mb-1 ml-1" v-else pill variant="danger" small>
              X
            </b-badge>
          </template>

        </b-table>

        <!--Table Paginator-->
        <div class="text-center w-100">
          <div class="d-inline-block">
            <b-pagination
                v-model="page.currentPage"
                :total-rows="page.total"
                :per-page="page.amount"
                first-number
                last-number
                @input="refresh"
            />
          </div>
          <div class="d-inline-block px-2"><strong>{{ $t('Total') }}</strong> {{ page.total }}</div>
        </div>
      </b-card-body>
    </b-card>

  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import contractCard from '@/components/pages/contracts/contractCard'
import {mapActions, mapGetters} from "vuex";
import RealEstatePicker from "@/components/picker/realEstatesPicker";
import CalendarPickerRange from "@/components/widgets/calendar/calendarPickerRange";

export default {
  name: "contracts",
  // eslint-disable-next-line vue/no-unused-components
  components: {contractCard, VueSlickCarousel, RealEstatePicker,CalendarPickerRange},
  data: () => ({
    carouselSettings: {
      dots: true,
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
    },
    datesRange:null,
    initialLoad: true, // Add this flag
    sort: {
      by: 'id',
      desc: true,
      direction: 'desc'
    },
    page: {
      amount: 10,
      currentPage: 1,
      total: 0
    },
    temp: {},
    fields: [],
    loading: true,

    filter: {
      like: {},
      eq: {client: {}, bnbStyleRent: {platform: null}},
      lte: {},
      gte: {},
      in: {}
    },
    realEstateId: null,
    platform: null,
    contracts: [],
    properties: {}
  }),
  watch: {
    datesRange: {
      handler() {
        this.refresh()
      }
    },
    filter: {
      deep: true,
      handler() {
        if (this.initialLoad) {
          this.initialLoad = false;
          return;
        }
        this.refresh()
      }
    }
  },
  created() {
    this.setFields();

    this.refresh()
  },
  computed: {
    ...mapGetters('contract', ['statusData', 'allStatus']),
    ...mapGetters('realEstates', ['allStatus', 'statusVariants', 'getPrices', "getFullAddress"]),
    allContractStatus() {
      return this.allStatus.map(status => ({
        value: status.value,
        text: this.$t(`contract.status.${status.text.toLowerCase()}`)
      }))
    },
    getUnderYear() {
      return this.$moment().add(1, 'year').format('YYYY-MM-DD');
    },
    getBetween1And2() {
      return this.$moment().add(1, 'year').format('YYYY-MM-DD');

    },
    over2Years() {
      return this.$moment().add(1, 'year').format('YYYY-MM-DD');

    },
  },
  methods: {
    ...mapActions('contract', ['getAll', 'getDocument']),
    downloadInvoice(item) {
      let $this = this;
      const file = item.files.find(item => item.tag === 'generatedInvoice');
      this.getDocument({fileId: file.id, contractId: item.id})
          .then(ans => $this.downloadDocument(ans.data, ans.fileName));

    },
    canDownload(item) {
      const currentDate = new Date();
      const parsedStartDate = new Date(item.start);

      // Compare dates
      return currentDate >= parsedStartDate;

    },
    setFields() {
      this.fields = [
        {key: "propertyId", label: this.$t("property.property")},
        {key: "client", label: this.$t("client")},
        {key: "moveDate", label: this.$t("Check in")},
        {key: "status", label: this.$t("contract.status.status")},
        {key: "bnbStyleRent", label: this.$t("Platform")},
        {key: "actions", label: this.$t("invoice")},
        {key: "creationTime", label: this.$t("created")},


      ];
    },
    convertDateFormat(date) {
      return this.$moment(date).format('DD.MM.YYYY');
    },
    convertDateFormatTime(date) {
      return this.$moment(date).format('DD.MM.YYYY HH:MM:SS');
    },
    onSort(data) {
      this.sort.by = data.sortBy;
      this.sort.direction = data.sortDesc ? "desc" : "asc";
      this.refresh();
    },
    onRowClick(contract) {

      this.$router.push("/contract/view/" + contract.id)
    },
    refresh() {
      this.loading = true;
      let $this = this;
      if (this.datesRange != null) {
        this.filter.gte.moveDate = this.datesRange.start
        this.filter.lte.moveDate = this.datesRange.end
      } else {
        this.filter.gte.moveDate = null
        this.filter.lte.moveDate = null
      }
      this.getAll({
        filter: $this.filter,
        page: $this.page.currentPage,
        amount: $this.page.amount,
        sortcolumn: $this.sort.by,
        sortby: $this.sort.direction,
      }).then(this.onContractsReceived);
    },
    onContractsReceived(data) {
      console.log("onContractsReceived", data)
      /* let activeContracts = contracts.filter(contract =>  ["ACTIVE"].indexOf(contract.status) >= 0)
       let futureContracts = contracts.filter(contract =>  ["NEW","REVIEWING", "WAITING_FOR_DATA", "WAITING_FOR_FILE"].indexOf(contract.status) >= 0)
       let beforeContracts = contracts.filter(contract =>  ["COMPLETED", "CANCELED"].indexOf(contract.status) >= 0)*/

      this.page = {
        amount: data['Amount'],
        total: data['Total'],
        currentPage: data['Page']
      };
      this.sort = {
        by: data["sortColumn"],
        direction: data["sortOrder"]
      }
      this.contracts = (data["contracts"] || [])
      this.properties = (data["RealEstates"] || []).toObject("id")
      this.loading = false;
    },

    getPropertiesPicture(property) {
      let images = property.attachments.filter(attachment => attachment.attachmentGroup === 'TITELBILD')

      if (!Array.isArray(images) || images.length === 0)
        return this.getSecondPropertiePicture(property);


      return 'https://treasurehome.at' + images[0].attachmentUrl;
    },
    getSecondPropertiePicture(property) {
      let images = property.attachments.filter(attachment => attachment.attachmentGroup === 'BILD')

      if (!Array.isArray(images) || images.length === 0)
        return require("@/assets/images/property-placeholder.jpg");
      return 'https://treasurehome.at' + images[0].attachmentUrl;
    },
    lastHistory(contract) {
      if (!Array.isArray(contract.statusHistories) || contract.statusHistories.length <= 0)
        return {time: contract.creationTime, status: (this.status || {}).key, note: this.$t("noInfo")};
      return contract.statusHistories[contract.statusHistories.length - 1]
    },
    convertToEURO(someNumber) {
      return new Intl.NumberFormat("de-AT", {
        style: "currency",
        currency: "EUR",
      }).format(someNumber);
    },
    goToContractPage(contract) {
      this.$router.push(`/contract/view/${contract.id}`)
    },
    getSource(platform) {
      if (platform == null || platform === 'Unknown')
        return "https://treasurehome.at/uploads/images/treasurehome.png"

      if (platform === 'Booking')
        return "https://treasurehome.at/uploads/images/booking.png"

      if (platform === 'Expedia')
        return "https://treasurehome.at/uploads/images/expedia.png"

      if (platform === 'Airbnb')
        return "https://treasurehome.at/uploads/images/airbnb.png"

      if (platform === 'Vrbo')
        return "https://treasurehome.at/uploads/images/vrbo.png"

      if (platform === 'Agoda')
        return "https://treasurehome.at/uploads/images/agoda.png"

      if (platform === 'Treasure Home')
        return "https://treasurehome.at/uploads/images/treasurehome.png"


    }
  },


}
</script>

<style lang="scss" scoped>
.contract-component {
  .component-container {
    min-height: 300px;
  }

  .feat_property.list .details {
    width: 70%
  }

  /*.img-whp {
    height: 275px;
  }*/

  .property_table .table {
    border: 1px solid #ebebeb;
    border-radius: 8px;
    margin-bottom: 30px
  }

  .property_table .table .thead-light th {
    background-color: rgb(36, 50, 74);
    border: none;
    font-size: 16px;
    font-family: "Quicksand", Helvetica, "Roboto", Arial, sans-serif !important;
    color: #fff;
    font-weight: 700;
    line-height: 2.2
  }

  .property_table .table td, .property_table .table th {
    font-size: 16px;
    font-family: "Quicksand", Helvetica, "Roboto", Arial, sans-serif !important;
    color: #484848;
    font-weight: 600;
    line-height: 3;
    padding-left: 30px
  }

  .property_table .table td, .property_table .table th {
    vertical-align: middle
  }

  .property_table td.para {
    font-size: 14px;
    font-family: "Quicksand", Helvetica, "Roboto", Arial, sans-serif !important;
    color: #484848
  }

  .feat_property.list .details .fp_footer {
    border: none;
    padding: 0 20px 10px
  }

  .feat_property.list .details .tc_content {
    padding: 25px 20px 10px;
    position: relative
  }

  .feat_property.list .dtls_headr {
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flex;
    display: -o-inline-flex;
    display: inline-flex
  }

  .feat_property.list .dtls_headr a.fp_price {
    font-size: 22px;
    font-family: "Quicksand", Helvetica, "Roboto", Arial, sans-serif !important;
    color: #484848;
    font-weight: 700;
    line-height: 1.2;
    position: absolute;
    right: 20px
  }

  .feat_property.list.favorite_page {
    border: none;
    border-radius: 0;
    box-shadow: none;
    border-bottom: 1px solid #ebebeb;
    padding: 0 0 30px
  }

  .feat_property.list.favorite_page .thumb {
    height: 126px;
    max-width: 150px;
    margin: 0;
    position: relative
  }

  .feat_property.list.favorite_page .view_edit_delete_list {
    position: absolute;
    right: 0
  }

  .feat_property.list.favorite_page.style2 {
    border-bottom: none;
    margin: 10px 0;
    margin-bottom: 0;
    padding-bottom: 0
  }

  .feat_property.list.favorite_page.style2 .details .tc_content p {
    margin-bottom: 0
  }

  .feat_property .thumb .thmb_cntnt .icon li {
    line-height: 39px;
  }

  .img-slick {
    width: 150px !important;
    height: 126px !important;
  }


}

.rounded-custom {
  border-top-right-radius: 30px !important; /* Adjust the radius as needed */
  border-bottom-right-radius: 30px !important; /* Adjust the radius as needed */
  /* Other CSS properties and styles for your div */
}
</style>
