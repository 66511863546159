<template>
  <b-card no-body class="h-100 contract-card property-data" v-if="property != null">
    <!-- image-->
    <b-card-header  class="p-0 position-relative cursor-pointer" style="height: 150px" @click="onClick">
      <div class="bg-image-holder" v-bind:style="{'background-image': `url('${getPropertiePicture(property)}')`}"></div>
      <div class="bg-image-holder show-on-hover"
           v-bind:style="{'background-image': `url('${getSecondPropertiePicture(property)}')`}"></div>
      <template v-if="status != null">
        <b-badge v-bind:id="`contract-status-${id}`" class="status-badge" v-bind:variant="statusData[status].variant">
          {{statusData[status].value }}
        </b-badge>
      </template>
    </b-card-header>
    <b-card-body class="pb-0">
      <!-- Data -->
      <div class="object-content">
        <h5 class="object-title border-0 m-0 p-0 text-capitalize">{{ property.address }} {{ property.house }}</h5>
        <p class="mb-1" style="font-size: 14px">{{ property.city }}, {{ property.country }}</p>
        <!--                      <hr class="my-1"/>-->
        <!--Data icons-->
        <ul class="object-details list-unstyled">
          <li class="d-inline-block mr-2" v-b-tooltip v-bind:title="$t('property.area')">
            <i class="fas fa-ruler-combined text-muted pr-1"></i>
            <span>{{ property.realEstateArea.totalSpace }}m<sup>2</sup></span>
          </li>
          <li class="d-inline-block mr-2" v-b-tooltip v-bind:title="$t('property.bedrooms')">
            <i class="fas fa-bed text-muted pr-1"></i>
            <span>{{ property.realEstateArea.rooms }}</span>
          </li>
          <li class="d-inline-block" v-if="property.floor != null" v-b-tooltip v-bind:title="$t('property.floor')">
            <i class="fas fa-building text-muted pr-1"></i>
            <span>{{ property.floor }}</span>
          </li>
        </ul>
        <div class="d-flex justify-content-between align-items-center">
          <!--            <span class="object-price d-inline-block font-weight-bold" style="font-size: 20px;">
                        {{ contract.moveDate | moment($t("dateFormat.date.format")) }}
                      </span>-->
        </div>
      </div>
      <div style="position: absolute;right: 10px;font-size: 12px">
        Last update: <span class="mx-1">{{lastHistory.time | moment($t("dateFormat.dateTime.format"))}}</span>
      </div>
    </b-card-body>
    <b-popover v-bind:target="`contract-status-${id}`" triggers="hover focus">
      <template #title class="p-0"><span class="py-1">{{statusData[status].value}}</span></template>
      <b-row style="min-width: 250px;width: 100%">
        <b-col cols="2" align-self="center" class="timeline-item text-center" v-bind:class="[` timeline-variant-${statusData[status].variant}`]">
          <i class="fas fa-circle timeline-item-point" v-bind:class="[`text-${statusData[status].variant}`]" style="font-size: 18px"></i>
        </b-col>
        <b-col>
          {{ lastHistory.note }}
          <p class="m-0 text-muted" style="font-size: 12px" >{{lastHistory.time | moment($t("dateFormat.dateTime.format"))}}</p>
        </b-col>

      </b-row>
    </b-popover>
  </b-card>
</template>

<script>
export default {
  name: "contractCard",
  props: {
    id: {
      default:()=>"0"
    },
    property: {},
    contract: {}
  },
  computed: {
    isFuture() {
      return this.$moment().diff(this.$moment(this.contract.startDate, "yyyy-MM-DD HH:mm"), "minute") < 0
    },
    status() {
      if (this.contract == null || this.contract.status == null)
        return null;
      return this.contract.status;
    },
    statusData() {
      return {
        "NEW": {variant: "info", value: this.$t("contract.status.new"),key:"NEW"},
        "REVIEWING": {variant: "warning", value: this.$t("contract.status.reviewing"),key:"REVIEWING"},
        "WAITING_FOR_DATA": {variant: "warning", value: this.$t("contract.status.waiting_for_data"),key:"WAITING_FOR_DATA"},
        "WAITING_FOR_FILE": {variant: "warning", value: this.$t("contract.status.waiting_for_file"),key:"WAITING_FOR_FILE"},
        "ACTIVE": {variant: "success", value: this.$t("contract.status.active"),key:"ACTIVE"},
        "COMPLETED": {variant: "success", value: this.$t("contract.status.completed"),key:"COMPLETED"},
        "CANCELED": {variant: "danger", value: this.$t("contract.status.cancelled"),key:"CANCELED"},
        "CANCELLED": {variant: "danger", value: this.$t("contract.status.cancelled"),key:"CANCELLED"},
      }
    },
    lastHistory(){
      if(!Array.isArray(this.contract.statusHistories) || this.contract.statusHistories.length <=0)
        return {time:this.contract.creationTime,status:(this.status || {}).key,note:this.$t("noInfo")};
      return this.contract.statusHistories[this.contract.statusHistories.length - 1]
    },
  },
  mounted () {
    this.id = this._uid
  },
  methods: {
    getPropertiePicture(property) {
      let images = property.attachments.filter(attachment => attachment.attachmentGroup === 'TITELBILD')

      if (!Array.isArray(images) || images.length === 0)
        return require("@/assets/images/property-placeholder.jpg");
      return images[0].attachmentUrl;
    },
    getSecondPropertiePicture(property) {
      let images = property.attachments.filter(attachment => attachment.attachmentGroup === 'BILD')

      if (!Array.isArray(images) || images.length === 0)
        return require("@/assets/images/property-placeholder.jpg");
      return images[0].attachmentUrl;
    },
    onClick() {
      this.$emit("view", this.contract.id)
    },
  }
}
</script>

<style lang="scss" scoped>
.contract-card {
  .status-badge {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0
  }
}
</style>
